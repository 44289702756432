import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { SENT_MESSAGE, CONVERSATION_ID } from 'utils/analytics_constants';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import { setupStream } from 'services/StreamService';

const DEFAULT_OPTIONS = {
  activeChannel: undefined,
  newActiveChannel: undefined,
  showNewChannelModal: false,
  showSettingsMenu: false, // settings menu in chat channel
  streamChatToken: undefined,
  streamClient: undefined,
  unreadChannelMap: {},
  unreadCount: undefined,
};

export const ConversationsContext = createContext({
  ...DEFAULT_OPTIONS,
  setProperty: () => {}
});

class ConversationsProvider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      ...DEFAULT_OPTIONS,
      gqlClient: props.gqlClient
    };

    this.setProperty = this.setProperty.bind(this);
  }

  async componentDidMount () {
    const { gqlClient, user } = this.props;
    const streamClient = await setupStream(this, gqlClient, user);

    if (this.props.user.subdomain === 'synthetics') return;

    const { total_unread_count: totalUnread, channels } = await streamClient.getUnreadCount();
    const unreadChannelMap = Object.fromEntries(
      channels.map(c => [
        // Stream is not consistent with channel_id format, so we normalize it
        c.channel_id.replace('messaging:', '').replace('bid-request-announcements:', ''),
        c.unread_count])
    );

    this.setState(
      { unreadChannelMap, unreadCount: totalUnread },
      // Add stream event listener as callback to ensure it is set after state is updated
      () => streamClient.on(event => this.handleStreamEvent(event, user))
    );
  }

  handleStreamEvent = (event, user) => {
    const { type, user: eventUser, channel_id: channelId, unread_messages: unreadMessages, total_unread_count: totalUnreadCount, cid } = event;
    const isMe = eventUser?.id === user.id.toString();

    if (type === 'message.new' || type === 'notification.message_new') {
      if (isMe) {
        if (cid) attachTrackingAnalytics(SENT_MESSAGE, { [CONVERSATION_ID]: cid });
        return;
      }
      this.updateUnreadState(channelId, totalUnreadCount, prev => (prev[channelId] || 0) + 1);
    } else if (type === 'notification.mark_read') {
      // Mark the channel as completely read
      this.updateUnreadState(channelId, totalUnreadCount, () => 0);
    } else if (type === 'message.read' && isMe) {
      // If we are subscribed to a channel, then sometimes only this event is fired off.
      // This event does not include totalUnreadCount, so we need to use the previous unread count of the channel.
      const newUnreadCount = this.state.unreadCount > 0 ?
        this.state.unreadCount - (this.state.unreadChannelMap[channelId] || 0) : 0;

      this.updateUnreadState(channelId, newUnreadCount, () => 0);
    }
    else if (type === 'notification.mark_unread') {
      this.updateUnreadState(channelId, totalUnreadCount, () => unreadMessages);
    }

  };

  setProperty (property) {
    this.setState(property);
  }

  updateUnreadState = (channelId, totalUnreadCount, updateFn) => {
    this.setState(prevState => ({
      unreadChannelMap: totalUnreadCount === 0 ? {} : { ...prevState.unreadChannelMap, [channelId]: updateFn(prevState.unreadChannelMap) },
      unreadCount: totalUnreadCount
    }));
  };

  render () {
    return (
      <ConversationsContext.Provider
        value={{
          ...this.state,
          setProperty: this.setProperty
        }}
      >
        {this.props.children}
      </ConversationsContext.Provider>
    );
  }
}

ConversationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  gqlClient: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired
};

const exportedComponent = props => (
  <UserContext.Consumer>
    {user => <ConversationsProvider {...props} user={user} />}
  </UserContext.Consumer>
);

export default exportedComponent;
